<div class="row">
  <div class="offset-1 col-10">
    <form [formGroup]="form" (ngSubmit)="submit(model)">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      <p>
        <button [disabled]="!form.valid" type="submit" class="btn btn-primary mr-2">Save</button>
        <button type="button" routerLink="/list" class="btn btn-secondary">Cancel</button>
      </p>
    </form>
  </div>
</div>
<app-error *ngIf="error" [error]="error"></app-error>