import { Component } from '@angular/core';
import { ExamService } from './exam.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(
    public service: ExamService
  ) {}
}
