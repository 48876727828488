import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ExamService } from './exam.service';
import { TimerComponent } from './timer.component';
import { ControlComponent } from './control.component';
import { ErrorComponent } from './error.component';
import { UserComponent } from './user.component';
import { ListComponent } from './list.component';
import { AddComponent } from './add.component';
import { LoginComponent } from './login.component';

@NgModule({
  declarations: [
    AppComponent,
    TimerComponent,
    ControlComponent,
    ErrorComponent,
    UserComponent,
    ListComponent,
    AddComponent,
    LoginComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    AppRoutingModule
  ],
  providers: [
    AngularFireAuthGuard,
    ExamService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
