<div class="row">
  <div class="offset-1 col-10">

    <div *ngIf="exam$|async as exam; else none">
      <h2 class="display-2">{{exam.name}}</h2>
      <hr>
      <h4 class="display-3 ml-5">
        <span *ngIf="exam.started; else waiting">
          Started: {{exam.started.toDate()|date:'h:mm a'}}
        </span>
        <ng-template #waiting>
          Start: {{exam.date.toDate()|date:'yyyy-MM-dd h:mm a'}}
        </ng-template>
      </h4>
      <h3 class="display-1 border border-primary rounded-lg shadow px-5">
        Time:
        <span [@tickTock]="tt">{{hhmm}}</span>
      </h3>
      <h4 *ngIf="endTime" class="display-3 ml-5">Ending: {{endTime}}</h4>
      <hr *ngIf="started">
      <h4 class="display-3">
        <span *ngIf="exam.state=='waiting'">Waiting for start...</span>
        <span *ngIf="exam.state=='stopped'">Examination ended.</span>
        <span *ngIf="exam.state=='started'">
          Elapsed: {{elapsed}} of {{exam.duration}} minutes
        </span>
      </h4>
      <ngb-progressbar *ngIf="percent" height="4rem" showValue="true" [type]="progress" [value]="percent"></ngb-progressbar>
    </div>

    <ng-template #none>
      <div *ngIf="!loading" class="alert alert-warning mt-3">
        Waiting for active session...
      </div>

      <div *ngIf="loading" class="alert alert-info mt-2">
        Loading, please wait...
      </div>
    </ng-template>

  </div>
</div>