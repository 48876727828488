import { Component, OnInit } from '@angular/core';
import { ExamService } from './exam.service';
import { Observable } from 'rxjs';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap, map } from 'rxjs/operators';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.sass']
})
export class ControlComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: ExamService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.error = null;
    this.loading = true;
    this.exam$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.id = params.get('id');
        if(this.id) {
          return this.service.getExam(this.id).pipe(
            map(exam => Object.assign({id: this.id}, exam))
          );
        } else {
          return this.service.currentExam().pipe(
            tap(exam => this.id = exam?exam.id:null)
          );
        }
      }),
      tap(_ => this.loading = false)
    );
  }

  public id: string;
  public exam$: Observable<any>;
  public error: any;
  public loading: boolean;

  private modifyState(id: string, state: string, extra: any) {
    this.service.changeState(id, state, extra)
      .then(_ => this.error = null)
      .catch(err => this.error = err);
  }

  start(id: string) {
    this.modifyState(id, 'started', {started: firestore.FieldValue.serverTimestamp()});
  }
  reset(id: string) {
    this.modifyState(id, 'waiting', {started: null, stopped: null});
  }
  stop(id: string) {
    this.modifyState(id, 'stopped', {stopped: firestore.FieldValue.serverTimestamp()});
  }

  current(id: string) {
    this.service.makeCurrent(id)
      .then(_ => this.error = null)
      .catch(err => this.error = err);
  }

  delete(id: string) {
    this.service.delete(id)
      .then(_ => this.router.navigate(['/list']))
      .catch(err => this.error = err);
  }

}
