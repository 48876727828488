import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ExamService } from './exam.service';
import { Router } from '@angular/router';
import { firestore } from 'firebase/app'

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.sass']
})
export class AddComponent implements OnInit {

  constructor(
    private service: ExamService,
    private router: Router
  ) {
    this.error = null;
  }

  error: any;

  form = new FormGroup({});
  model = { name: '', date: '', duration: 180 };
  fields: FormlyFieldConfig[] = [{
    key: 'name',
    type: 'input',
    templateOptions: {
      label: 'Exam name',
      placeholder: 'Enter name',
      description: "Provide the name of your exam",
      required: true,
    }
  }, {
    key: 'duration',
    type: 'input',
    templateOptions: {
      label: 'Exam length (minutes)',
      placeholder: 'Duration',
      description: "Please give the exam duration in minutes",
      type: 'number',
      min: 30,
      max: 360,
      required: true,
    }
  }, {
    key: 'date',
    type: 'input',
    templateOptions: {
      label: 'Exam date',
      placeholder: 'YYYY-MM-DD',
      description: "Enter the date the exam is being written",
      type: 'datetime-local',
      required: true,
    }
  }];

  submit(model) {
    this.service.add(Object.assign(
      {},
      model, {
        date: firestore.Timestamp.fromDate(new Date(model.date)),
        state: 'waiting',
        current: false
      }))
      .then(_ => this.router.navigate(['/list']))
      .catch(err => {
        this.error = err;
        console.warn("Error adding exam", err.message);
      });
  }

  ngOnInit() {
    this.error = null;
  }

}
