<div class="row" *ngIf="user$|async as user; else nouser">
  <div class="offset-1 col-7">
    <h3 class="mt-2">You are logged in as:</h3>
    <app-user [user]="user"></app-user>
    <p class="mt-4">
      <button type="button" (click)="logout()" class="btn btn-primary mr-2">Log out</button>
      <button type="button" routerLink="/list" class="btn btn-success mr-2">Proceed</button>
    </p>
  </div>
</div>
<ng-template #nouser>
  <div *ngIf="loading" class="row">
    <div class="offset-1 col-10 mt-2">
      <div class="alert alert-info">Loading...</div>
    </div>
  </div>
  <div *ngIf="!loading">
    <app-error [code]="401"></app-error>
    <div class="row">
      <div class="offset-1 col-10">
        <p class="mt-2">
          <button type="button" (click)="login()" class="btn btn-primary">Log in</button>
        </p>
      </div>
    </div>
  </div>
</ng-template>