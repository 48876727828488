<div class="row">
  <div class="offset-1 col-7">
    <h2>Controls</h2>
    <div *ngIf="exam$|async as exam; else none">
      <table class="table table-striped table-condensed">
        <thead class="bg-dark text-light">
          <tr *ngIf="exam.current">
            <th colspan="2">Current session</th>
          </tr>
          <tr *ngIf="!exam.current">
            <th colspan="2">Future session</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Name:</th>
            <td>{{exam.name}}</td>
          </tr>
          <tr>
            <th>Date:</th>
            <td>{{exam.date.toDate()|date:'yyyy-MM-dd h:mm a'}}</td>
          </tr>
          <tr>
            <th>Length:</th>
            <td>{{exam.duration}} minutes</td>
          </tr>
          <tr>
            <th>State:</th>
            <td>{{exam.state}}</td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="exam.current">
        <button type="button" [disabled]="exam.state != 'waiting'" (click)="start(exam.id)"
          class="btn btn-success mx-2">Start</button>
        <button type="button" [disabled]="exam.state != 'stopped'" (click)="reset(exam.id)"
          class="btn btn-warning mx-2">Reset</button>
        <button type="button" [disabled]="exam.state != 'started'" (click)="stop(exam.id)"
          class="btn btn-danger mx-2">Stop</button>
          <button type="button" routerLink="/timer" class="btn btn-primary ml-5">Timer</button>
          <button type="button" routerLink="/list" class="btn btn-secondary ml-2">Back to list</button>
      </p>
      <p *ngIf="!exam.current">
        <button type="button" [disabled]="exam.current" (click)="current(exam.id)" class="btn btn-primary mx-2">Set as
          current</button>
        <button type="button" [disabled]="exam.current" (click)="delete(exam.id)" class="btn btn-danger mx-2">Delete</button>
        <button type="button" routerLink="/list" class="btn btn-secondary mx-2">Back</button>
      </p>
    </div>
    <ng-template #none>
      <div *ngIf="!loading" class="alert alert-warning mt-2">
        Waiting for session...
      </div>
      <div *ngIf="loading" class="alert alert-info mt-2">
        Loading, please wait...
      </div>
    </ng-template>
  </div>
</div>
<app-error [error]="error"></app-error>
