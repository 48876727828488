import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';
import { TimerComponent } from './timer.component';
import { ControlComponent } from './control.component';
import { ErrorComponent } from './error.component';
import { ListComponent } from './list.component';
import { AddComponent } from './add.component';
import { LoginComponent } from './login.component';

const redirectUnauthorized = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  { path: '', redirectTo: '/list', pathMatch: 'full' },
  { path: 'timer', component: TimerComponent },
  { path: 'login', component: LoginComponent },
  { path: 'list', component: ListComponent, ...canActivate(redirectUnauthorized) },
  { path: 'ctrl', component: ControlComponent, ...canActivate(redirectUnauthorized) },
  { path: 'ctrl/:id', component: ControlComponent, ...canActivate(redirectUnauthorized) },
  { path: 'add', component: AddComponent, ...canActivate(redirectUnauthorized) },
  { path: 'error/:code', component: ErrorComponent },
  { path: '**', redirectTo: '/error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
