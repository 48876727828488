<div class="row" *ngIf="error||code">
  <div class="offset-1 col-10">
    <div class="alert alert-danger mt-3">
      <h4 class="alert-heading">
        Error<span *ngIf="code"> (code {{code}})</span>
      </h4>
      <hr>
      <i>{{error?.message||error||"An error occurred"}}</i>
    </div>
  </div>
</div>
