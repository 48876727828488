import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExamService {

  constructor(
    private db: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {
    console.info("ExamService instantiated.");
    this.user$ = this.afAuth.user;
  }

  getExams(): Observable<any[]> {
    return this.db.collection('exams', ref =>
      ref.orderBy('date')
    ).valueChanges({idField: 'id'});
  }

  getExam(id: string): Observable<any> {
    return this.db.collection('exams').doc(id).valueChanges();
  }

  currentExam(): Observable<any> {
    return this.db.collection('exams', ref => ref.where('current', '==', true))
      .valueChanges({idField: 'id'})
      .pipe(
        map(list => list.length == 0 ? null : list[0])
      );
  }

  changeState(id: string, state: string, extra: any): Promise<void> {
    return this.db.collection('exams').doc(id).update(Object.assign({state: state}, extra));
  }

  delete(id: string): Promise<void> {
    return this.db.collection('exams').doc(id).delete();
  }

  add(exam: any): Promise<any> {
    //return Promise.reject(new Error("Sorry"));
    return this.db.collection('exams').add(exam);
  }

  makeCurrent(id: string): Promise<any> {
    const ref = this.db.collection('exams');
    return this.currentExam().pipe(
      take(1),
      map(old => {
        if(old && old.id) {
          return from(ref.doc(old.id).update({current: false})
            .then(_ => ref.doc(id).update({current: true})));
        } else {
          return from(ref.doc(id).update({current: true}));
        }
      })
    ).toPromise()
  }

  login(): Promise<void> {
    return this.afAuth.signInWithRedirect(new auth.GoogleAuthProvider());
  }

  logout(): Promise<void> {
    return this.afAuth.signOut();
  }

  public user$: Observable<firebase.User|null>;
}
