import { Component, OnInit } from '@angular/core';
import { ExamService } from './exam.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private service: ExamService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.user$ = this.service.user$.pipe(
      tap(_ => this.loading = false)
    );
  }

  user$: Observable<any>;
  loading: boolean;

  logout() {
    this.loading = true;
    this.service.logout()
      .then(_ => this.loading = false)
      .catch(err => {
        this.loading = false;
        console.error(err);
      });
  }

  login() {
    this.loading = true;
    this.service.login()
    .then(_ => {
      this.router.navigate(['/list']); //Does nothing...
      this.loading = false;
    })
    .catch(err => {
      this.loading = false;
      console.error(err);
    });
  }

}
