import { Component, OnInit } from '@angular/core';
import { ExamService } from './exam.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  constructor(
    private service: ExamService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.loading = true;
    this.exams$ = this.service.getExams().pipe(
      tap(_ => this.loading = false)
    )
  }

  public loading: boolean;
  public exams$: Observable<any[]>;

}
