<div class="row" *ngIf="exams$|async as exams; else none">
  <div class="offset-1 col-10">
    <table class="table table-condensed table-striped table-border my-3">
      <thead class="bg-dark text-light">
        <tr>
          <th>Name <span class="font-italic">(** = current exam)</span></th>
          <th>Date</th>
          <th>Length</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let e of exams" [class.font-italic]="e.current" [class.font-weight-bold]="e.current">
          <td class="text-truncate">
            <span *ngIf="e.current">** </span>
            <a routerLink="/ctrl/{{e.id}}">{{e.name}}</a>
          </td>
          <td class="text-nowrap">{{e.date.toDate()|date:'yyyy-MM-dd h:mm a'}}</td>
          <td class="text-nowrap">{{e.duration}}m</td>
          <td class="text-nowrap">{{e.state}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #none>
  <app-error *ngIf="!loading" [error]="'No exams found'"></app-error>
  <div *ngIf="loading" class="row mt-3">
    <div class="offset-1 col-5">
      <div class="alert alert-info">Loading, please wait...</div>
    </div>
  </div>
</ng-template>
<div class="row">
  <div class="offset-1 col-10">
    <p>
      <button type="button" routerLink="/add" class="btn btn-primary">Add exam</button>
    </p>
  </div>
</div>