import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, transition, style, animate, keyframes } from '@angular/animations';
import { ExamService } from './exam.service';
import { Observable, interval, Subscription } from 'rxjs';
import { tap, map, distinctUntilChanged } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import * as moment from 'moment';

@Component({
  selector: 'app-timer',
  animations: [
    trigger('tickTock', [
      state('tick', style({opacity: 1})),
      state('tock', style({opacity: 1})),
      transition('tick <=> tock', [
        animate('0.4s', keyframes([
          style({opacity: 0.8}),
          style({opacity: 0.6}),
          style({opacity: 0.4}),
          style({opacity: 0.2}),
          style({opacity: 0.4}),
          style({opacity: 0.6}),
          style({opacity: 0.8}),
        ]))
      ])
    ])
  ],
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.sass']
})
export class TimerComponent implements OnInit, OnDestroy {
  constructor(
    private service: ExamService
  ) {
    this.loading = true;
    this.started = false;
    this.percent = 0;
    this.progress = 'primary';
    this.endTime = null;
    this._clockSub = null;
  }

  public loading: boolean;
  public hhmm: string;
  public tt: string;
  public id: string;
  public started: boolean;
  public startMillis: number;
  public duration: number;
  public elapsed: number;
  public percent: number;
  public progress: string;
  public endTime: string;

  private localHHMM(): string {
    let now = moment().format("h:mm a");
    return now;
  }

  private calcElapsed(): void {
    if(this.started) {
      this.elapsed = Math.floor((new Date().getTime() - this.startMillis) / 60000);
      this.percent = Math.round(Math.min(100, this.elapsed * 100 / this.duration));
      if(this.percent == 100) {
        this.progress = 'danger';
        //TODO: firestore rules block this for anon
        this.service.changeState(this.id, 'stopped', {stopped: firestore.FieldValue.serverTimestamp()});
      } else if(this.duration - this.elapsed <= 5) {
        this.progress = 'warning';
      } else {
        this.progress = 'primary';
      }
    }
  }

  ngOnInit() {
    console.info("TimerComponent init");
    this.loading = true;
    //Load current exam
    this.exam$ = this.service.currentExam().pipe(
      tap(_ => this.loading = false),
      tap(exam => {
        this.id = exam.id;
        console.info("Timer running for exam", this.id);
        this.hhmm = this.localHHMM();
        this.duration = exam.duration;
        this.started = exam.state == 'started';
        if(this.started) {
          this.startMillis = exam.started.toDate().getTime();
          this.endTime = moment(this.startMillis).add(exam.duration, 'm').format("h:mm a");
          this.calcElapsed();
        } else if(exam.state == 'stopped') {
          //this.endTime = null;
          this.percent = 100;
          this.progress = 'danger';
        }
      })
    )
    //Tick the clock every second:
    this._clockSub = interval(1000).pipe(
      map(_ => this.localHHMM()),
      distinctUntilChanged(),
      tap(hhmm => {
        this.tt = (this.tt=='tick')?'tock':'tick';
        setTimeout(_ => this.hhmm = hhmm, 200);
      }),
      tap(_ => this.calcElapsed())
    ).subscribe(_ => {}, err => console.error(err));
  }

  ngOnDestroy(): void {
    if(this._clockSub !== null) {
      this._clockSub.unsubscribe();
      this._clockSub = null;
    }
  }

  public exam$: Observable<any>;
  private _clockSub: Subscription;

}
