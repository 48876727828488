import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private route: ActivatedRoute
  ) {
    this._sub = null;
  }

  @Input()
  error: any;

  @Input()
  code: number;

  @Input()
  timeout: number;

  ngOnInit() {
    this._sub = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        let code = +params.get('code');
        return of(code);
      })
    ).subscribe(code => this.setErrorFromCode(code));
  }

  ngOnChanges(changes: SimpleChanges): void {
    //TODO only if code changed
    if(this.code) this.setErrorFromCode(this.code);
  }

  ngOnDestroy(): void {
    if(this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  setErrorFromCode(code: number) {
    if(!code) return;
    this.code = code;
    switch(code) {
      case 404:
        this.error = new Error("Page not found"); break;
      case 401:
        this.error = new Error("Unauthorized"); break;
      default:
        if(!this.error) this.error = new Error("Unknown error");
    }
  }

  private _sub: Subscription;

}
